import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Trade from './components/Trade';
import Help from './components/Help';
import Assets from './components/Assets';
import Register from './components/Register';
import Login from './components/Login';
import { Modal, Button } from 'react-bootstrap';
import backgroundImage from './assets/background.png';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [username, setUsername] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', body: '' });

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const appStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    padding: '20px',
    position: 'relative',
    zIndex: 1,
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // Сделаем фон более прозрачным
    zIndex: -1,
  };

  return (
    <Router>
      <div style={appStyle}>
        <div style={overlayStyle}></div> {/* Добавляем полупрозрачный слой */}
        <div className="dashboard">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
              <Link className="navbar-brand" to="/">Home</Link>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link" to="/about">About</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/trade">Trade</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/help">Help</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/assets">Assets</Link>
                  </li>
                </ul>
              </div>
              <div className="d-flex">
                {isAuthenticated ? (
                  <Button variant="outline-danger" onClick={() => setIsAuthenticated(false)}>Logout</Button>
                ) : (
                  <>
                    <Link className="btn btn-outline-primary" to="/login">Login</Link>
                    <Link className="btn btn-outline-primary ml-2" to="/register">Register</Link>
                  </>
                )}
              </div>
            </div>
          </nav>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/trade" element={<Trade />} />
            <Route path="/help" element={<Help />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} setUsername={setUsername} />} />
            <Route path="/assets" element={isAuthenticated ? <Assets userId="123" username={username} openModal={openModal} /> : <Navigate to="/login" />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        <Modal show={modalIsOpen} onHide={closeModal}>
          <Modal.Header closeButton>
            <Modal.Title>{modalContent.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalContent.body}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </Router>
  );
};

export default App;
