import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import QRCode from 'qrcode.react';

const DepositModal = ({ show, onHide, coin, username }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [network, setNetwork] = useState('erc20');
  const [existingAddresses, setExistingAddresses] = useState([]);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get(`http://91.192.23.146:4444/addresses/${username}`);
        setExistingAddresses(response.data);
      } catch (error) {
        console.error('Error fetching addresses:', error);
      }
    };

    fetchAddresses();
  }, [username]);

  const generateAddress = async () => {
    setIsGenerating(true);
    try {
      let response;
      let networkType;
      if (coin.currency === 'usdt') {
        response = await axios.get(`http://91.192.23.146:3333/generate/usdt?network=${network}`);
        networkType = network.toUpperCase();
      } else if (coin.currency === 'opg') {
        response = await axios.get(`http://91.192.23.146:3333/generate/opg`);
        networkType = 'OPG Chain';
      } else if (coin.name.toLowerCase() === 'ethereum') {
        response = await axios.get(`http://91.192.23.146:3333/generate/ethereum`);
        networkType = 'ERC20';
      } else {
        response = await axios.get(`http://91.192.23.146:3333/generate/${coin.name.toLowerCase()}`);
        networkType = coin.name.toUpperCase();
      }

      if (!response.data || !response.data.address) {
        throw new Error('No address returned from server');
      }

      const { address } = response.data;
      await axios.post('http://91.192.23.146:4444/generate-address', { username, coin: coin.name, address, network: networkType });
      setExistingAddresses([...existingAddresses, { username, coin: coin.name, address, network: networkType }]);
    } catch (error) {
      console.error('Error generating address:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleNetworkChange = (e) => {
    setNetwork(e.target.value);
  };

  const filteredAddresses = existingAddresses.filter(addr => addr.coin === coin.name && (coin.currency !== 'usdt' || addr.network === network.toUpperCase()));

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Deposit {coin.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {coin.currency === 'usdt' && (
          <Form.Group controlId="usdtNetworkSelect">
            <Form.Label>Select Network</Form.Label>
            <Form.Control as="select" value={network} onChange={handleNetworkChange}>
              <option value="erc20">ERC20</option>
              <option value="trc20">TRC20</option>
              <option value="bsc">BSC</option>
              <option value="ton">TON</option>
            </Form.Control>
          </Form.Group>
        )}
        {filteredAddresses.length > 0 ? (
          <>
            <p>Existing Addresses:</p>
            {filteredAddresses.map((addr, index) => (
              <div key={index}>
                <p>Address ({addr.network}): {addr.address}</p>
                <QRCode value={addr.address} size={128} />
              </div>
            ))}
          </>
        ) : (
          <>
            <Button onClick={generateAddress} disabled={isGenerating}>
              {isGenerating ? 'Generating...' : `Generate ${coin.name} Address`}
            </Button>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DepositModal;
