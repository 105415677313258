import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import backgroundImage from '../assets/background.png';

const Home = () => {
  const homeStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    padding: '20px',
    position: 'relative',
    zIndex: 1,
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: -1,
  };

  return (
    <div style={homeStyle}>
      <div style={overlayStyle}></div>
      <div className="container">
        <h1>Welcome to the Exchange</h1>
        <p>Select an option from the menu.</p>
      </div>
    </div>
  );
};

export default Home;
