import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const WithdrawModal = ({ show, onHide, coin }) => {
  const [withdrawAddress, setWithdrawAddress] = useState('');
  const [network, setNetwork] = useState('erc20');
  const [amount, setAmount] = useState('');
  const [addresses, setAddresses] = useState([]);
  const [newAddress, setNewAddress] = useState('');

  const handleWithdraw = async () => {
    // Логика обработки вывода будет добавлена позже
  };

  const addAddress = () => {
    if (newAddress) {
      setAddresses([...addresses, newAddress]);
      setNewAddress('');
    }
  };

  const handleNetworkChange = (e) => {
    setNetwork(e.target.value);
  };

  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>Withdraw {coin.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="withdrawAddress">
          <Form.Label>Withdraw Address</Form.Label>
          <Form.Control as="select" value={withdrawAddress} onChange={(e) => setWithdrawAddress(e.target.value)}>
            {addresses.map((address, index) => (
              <option key={index} value={address}>
                {address}
              </option>
            ))}
          </Form.Control>
          <Form.Control
            type="text"
            placeholder="Add new address"
            value={newAddress}
            onChange={(e) => setNewAddress(e.target.value)}
          />
          <Button onClick={addAddress} variant="primary" style={{ marginTop: '10px' }}>
            Add Address
          </Button>
        </Form.Group>
        {coin.currency === 'usdt' && (
          <Form.Group controlId="networkSelect" style={{ marginTop: '20px' }}>
            <Form.Label>Select Network</Form.Label>
            <Form.Control as="select" value={network} onChange={handleNetworkChange}>
              <option value="erc20">ERC20</option>
              <option value="trc20">TRC20</option>
              <option value="bsc">BSC</option>
              <option value="ton">TON</option>
            </Form.Control>
          </Form.Group>
        )}
        <Form.Group controlId="withdrawAmount" style={{ marginTop: '20px' }}>
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="number"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          />
        </Form.Group>
        {/* Блок для расчета комиссии будет добавлен позже */}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleWithdraw}>
          Withdraw
        </Button>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WithdrawModal;
