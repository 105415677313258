import React, { useEffect, useState } from 'react';
import axios from 'axios';
import bitcoinLogo from '../assets/bitcoin.png';
import ethereumLogo from '../assets/ethereum.png';
import opgLogo from '../assets/opg.png';
import tonLogo from '../assets/ton.png';
import usdtLogo from '../assets/usdt.png';
import DepositModal from './DepositModal';
import WithdrawModal from './WithdrawModal';
import TradeModal from './TradeModal';
import './AssetsTable.css';

const coinLogos = {
  btc: bitcoinLogo,
  eth: ethereumLogo,
  opg: opgLogo,
  ton: tonLogo,
  usdt: usdtLogo
};

const AssetsTable = ({ userId, username }) => {
  const [assets, setAssets] = useState([]);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [modalType, setModalType] = useState('');

  useEffect(() => {
    const fetchAssets = async () => {
      try {
        const response = await axios.get(`http://91.192.23.146:4444/assets/${username}`);
        setAssets(response.data);
      } catch (error) {
        console.error('Error fetching assets:', error);
      }
    };

    fetchAssets();
  }, [userId]);

  const coins = [
    { currency: 'btc', name: 'Bitcoin' },
    { currency: 'eth', name: 'Ethereum' },
    { currency: 'usdt', name: 'USDT' },
    { currency: 'ton', name: 'TON' },
    { currency: 'opg', name: 'OPG Chain' }
  ];

  const openModalHandler = (coin, type) => {
    setSelectedCoin(coin);
    setModalType(type);
  };

  const closeModalHandler = () => {
    setSelectedCoin(null);
    setModalType('');
  };

  return (
    <div className="assets">
      <h3>Your Assets</h3>
      <table className="table">
        <thead>
          <tr>
            <th>Currency</th>
            <th>Total Balance</th>
            <th>Available Balance</th>
            <th>Frozen Balance</th>
            <th>Value in USDT</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {coins.map((coin, index) => (
            <tr key={index}>
              <td>
                <img 
                  src={coinLogos[coin.currency]} 
                  alt={coin.name} 
                  className="coin-logo" 
                />
                {coin.name}
              </td>
              <td>{assets[coin.currency]?.totalBalance || 0}</td>
              <td>{assets[coin.currency]?.availableBalance || 0}</td>
              <td>{assets[coin.currency]?.frozenBalance || 0}</td>
              <td>{assets[coin.currency]?.valueInUSDT || 0}</td>
              <td className="actions">
                <button className="btn btn-deposit" onClick={() => openModalHandler(coin, 'Deposit')}>Deposit</button>
                <button className="btn btn-withdraw" onClick={() => openModalHandler(coin, 'Withdraw')}>Withdraw</button>
                <button className="btn btn-trade" onClick={() => openModalHandler(coin, 'Trade')}>Trade</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedCoin && modalType === 'Deposit' && (
        <DepositModal show={true} onHide={closeModalHandler} coin={selectedCoin} username={username} />
      )}
      {selectedCoin && modalType === 'Withdraw' && (
        <WithdrawModal show={true} onHide={closeModalHandler} coin={selectedCoin} username={username} />
      )}
      {selectedCoin && modalType === 'Trade' && (
        <TradeModal show={true} onHide={closeModalHandler} coin={selectedCoin} username={username} />
      )}
    </div>
  );
};

export default AssetsTable;






















