import React, { useEffect, useState } from 'react';
import axios from 'axios';

const Balance = ({ userId }) => {
  const [totalBalance, setTotalBalance] = useState(0);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        const response = await axios.get(`https://your-backend-url.com/api/users/${userId}/balance`);
        setTotalBalance(response.data.totalBalance);
      } catch (error) {
        console.error('Error fetching balance:', error);
      }
    };

    fetchBalance();
  }, [userId]);

  return (
    <div className="balance">
      <h2>Total Balance: {totalBalance} USDT</h2>
    </div>
  );
};

export default Balance;
