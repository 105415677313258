import React from 'react';
import Balance from './Balance';
import AssetsTable from './AssetsTable';
import TransactionsTable from './TransactionsTable';
import 'bootstrap/dist/css/bootstrap.min.css';
import backgroundImage from '../assets/background.png';

const Assets = ({ userId, username, openModal }) => {
  const appStyle = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
    padding: '20px',
    position: 'relative',
    zIndex: 1,
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    zIndex: -1,
  };

  return (
    <div style={appStyle}>
      <div style={overlayStyle}></div>
      <div className="dashboard">
        <div className="header-title">
          <Balance userId={userId} />
        </div>
        <AssetsTable userId={userId} username={username} openModal={openModal} />
        <TransactionsTable userId={userId} />
      </div>
    </div>
  );
};

export default Assets;
